import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBLPvWm0pxT46ZtHTBiqSAZwZ7KLKiiS8Q",
  authDomain: "metawurks-fb416.firebaseapp.com",
  projectId: "metawurks-fb416",
  storageBucket: "metawurks-fb416.appspot.com",
  messagingSenderId: "36614289320",
  appId: "1:36614289320:web:26d576258ab5627b204f9d",
  measurementId: "G-KEQ30S4QC3",
};

var firbaseApp = firebase.initializeApp(firebaseConfig);
var db = firbaseApp.firestore();

export { db };
