import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./views/layout/Header";
import Main from "./views/layout/Main";
import Footer from "./views/layout/Footer";
import About from "./views/about/About";
import Contact from "./views/contact/Contact";
import BookDemo from "./views/bookdemo/BookDemo";
import Timeline from "./views/about/Timeline";

export default function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/bookdemo" element={<BookDemo />} />
        <Route path="/timeline" element={<Timeline />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
