import React, { useState } from "react";
import addDataTotheDB from "../../api/api";
import { ReactComponent as DemoIllustration } from "../../images/demoIllus.svg";

const Partner = () => {
  const [fname, setfName] = useState("");
  const [lname, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    // db.collection("contacts")
    e.preventDefault();
    setLoader(true);
    addDataTotheDB({
      company: company,
      first_name: fname,
      last_name: lname,
      email: email,
      subject: subject,
      message: message,
      page: "bookademo",
    })
      .then(() => {
        setLoader(false);
        alert(
          "We have received your message. Please give us 2-3 business days to respond."
        );
        setfName("");
        setlName("");
        setEmail("");
        setCompany("");
        setSubject("");
        setMessage("");
      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });
  };

  return (
    <div>
      <section class="relative flex flex-col items-center justify-center py-12 bg-white bg-cover md:py-16 lg:py-24 min-w-screen tails-bg">
        <div class="flex flex-col flex-col-reverse items-center justify-center p-10 mx-auto lg:flex-row lg:max-w-6xl lg:p-0">
          <div class="container relative z-20 flex flex-col w-full px-5 pr-12 mb-16 text-2xl text-gray-700 lg:w-1/2 sm:px-0 md:px-10 sm:items-center lg:items-start lg:mb-0">
            <h1 class="relative z-20 font-sans text-4xl font-extrabold leading-none text-black sm:text-5xl xl:text-6xl sm:text-center lg:text-left">
              <span class="relative">
                <span class="absolute bottom-0 left-0 inline-block w-full h-4 mb-1 -ml-1 transform -skew-x-3 bg-themevoilet-100"></span>
                <span class="relative">metawurks,</span>
              </span>
              <span class="relative block text-themevoilet-100">
                is packed with all tools in one place.
              </span>
            </h1>
          </div>
          <div class="relative w-full px-8 mb-12 rounded-lg cursor-pointer md:px-0 lg:mb-0 lg:pl-10 md:w-2/3 lg:w-1/2 group">
            <div class="relative rounded-md">
              <DemoIllustration />
            </div>
          </div>
        </div>
      </section>

      <div class="py-10 bg-white md:py-16">
        <div class="px-10 mx-auto max-w-7xl md:px-16">
          <div class="max-w-3xl mx-auto mb-10 md:mb-16">
            <p class="text-xs font-bold text-themevoilet-100 uppercase">
              Book a demo
            </p>
            <h2 class="mt-1 text-2xl font-bold text-left text-gray-800 lg:text-3xl md:mt-2">
              Fill the form to book a demo
            </h2>
            <p class="max-w-screen-md mx-auto mt-4 text-left text-gray-500 md:text-lg md:mt-6">
              Our team will get back to you within 24-48 hours.
            </p>
          </div>
          <form class="grid max-w-3xl gap-4 mx-auto sm:grid-cols-2">
            <div>
              <label
                for="first-name"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                First name
              </label>
              <input
                value={fname}
                onChange={(e) => setfName(e.target.value)}
                name="first-name"
                class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              />
            </div>

            <div>
              <label
                for="last-name"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                Last name
              </label>
              <input
                value={lname}
                onChange={(e) => setlName(e.target.value)}
                name="last-name"
                class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              />
            </div>

            <div class="sm:col-span-2">
              <label
                for="company"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                Company
              </label>
              <input
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                name="company"
                class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              />
            </div>

            <div class="sm:col-span-2">
              <label
                for="email"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                Email
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              />
            </div>

            <div class="sm:col-span-2">
              <label
                for="subject"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                Subject
              </label>
              <input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                name="subject"
                class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              />
            </div>

            <div class="sm:col-span-2">
              <label
                for="message"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                Message
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                class="w-full h-64 px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              ></textarea>
            </div>
          </form>
        </div>
      </div>

      <section class="bg-white">
        <div class="px-8 py-8 mx-auto max-w-7xl">
          <div class="relative px-6 py-10 overflow-hidden   rounded-2xl lg:p-16 lg:flex lg:flex-col lg:items-center lg:justify-between">
            <div class="absolute bottom-0 right-0 z-10 hidden h-full p-4 mt-1 ml-3 -mb-4 -mr-4 transform rotate-90 md:block">
              <svg
                class="w-auto h-full fill-current text-themevoilet-100"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 390 390"
              >
                <defs></defs>
                <g fill-rule="nonzero">
                  <circle cx="10" cy="10" r="9.5"></circle>
                  <circle cx="47" cy="10" r="9.5"></circle>
                  <circle cx="84" cy="10" r="9.5"></circle>
                  <circle cx="121" cy="10" r="9.5"></circle>
                  <circle cx="158" cy="10" r="9.5"></circle>
                  <circle cx="195" cy="10" r="9.5"></circle>
                  <circle cx="232" cy="10" r="9.5"></circle>
                  <circle cx="269" cy="10" r="9.5"></circle>
                  <circle cx="306" cy="10" r="9.5"></circle>
                  <circle cx="343" cy="10" r="9.5"></circle>
                  <circle cx="380" cy="10" r="9.5"></circle>
                  <circle cx="47" cy="47" r="9.5"></circle>
                  <circle cx="84" cy="47" r="9.5"></circle>
                  <circle cx="121" cy="47" r="9.5"></circle>
                  <circle cx="158" cy="47" r="9.5"></circle>
                  <circle cx="195" cy="47" r="9.5"></circle>
                  <circle cx="232" cy="47" r="9.5"></circle>
                  <circle cx="269" cy="47" r="9.5"></circle>
                  <circle cx="306" cy="47" r="9.5"></circle>
                  <circle cx="343" cy="47" r="9.5"></circle>
                  <circle cx="380" cy="47" r="9.5"></circle>
                  <circle cx="84" cy="84" r="9.5"></circle>
                  <circle cx="121" cy="84" r="9.5"></circle>
                  <circle cx="158" cy="84" r="9.5"></circle>
                  <circle cx="195" cy="84" r="9.5"></circle>
                  <circle cx="232" cy="84" r="9.5"></circle>
                  <circle cx="269" cy="84" r="9.5"></circle>
                  <circle cx="306" cy="84" r="9.5"></circle>
                  <circle cx="343" cy="84" r="9.5"></circle>
                  <circle cx="380" cy="84" r="9.5"></circle>
                  <circle cx="121" cy="121" r="9.5"></circle>
                  <circle cx="158" cy="121" r="9.5"></circle>
                  <circle cx="195" cy="121" r="9.5"></circle>
                  <circle cx="232" cy="121" r="9.5"></circle>
                  <circle cx="269" cy="121" r="9.5"></circle>
                  <circle cx="306" cy="121" r="9.5"></circle>
                  <circle cx="343" cy="121" r="9.5"></circle>
                  <circle cx="380" cy="121" r="9.5"></circle>
                  <circle cx="158" cy="158" r="9.5"></circle>
                  <circle cx="195" cy="158" r="9.5"></circle>
                  <circle cx="232" cy="158" r="9.5"></circle>
                  <circle cx="269" cy="158" r="9.5"></circle>
                  <circle cx="306" cy="158" r="9.5"></circle>
                  <circle cx="343" cy="158" r="9.5"></circle>
                  <circle cx="380" cy="158" r="9.5"></circle>
                  <circle cx="195" cy="195" r="9.5"></circle>
                  <circle cx="232" cy="195" r="9.5"></circle>
                  <circle cx="269" cy="195" r="9.5"></circle>
                  <circle cx="306" cy="195" r="9.5"></circle>
                  <circle cx="343" cy="195" r="9.5"></circle>
                  <circle cx="380" cy="195" r="9.5"></circle>
                  <circle cx="232" cy="232" r="9.5"></circle>
                  <circle cx="269" cy="232" r="9.5"></circle>
                  <circle cx="306" cy="232" r="9.5"></circle>
                  <circle cx="343" cy="232" r="9.5"></circle>
                  <circle cx="380" cy="232" r="9.5"></circle>
                  <circle cx="269" cy="269" r="9.5"></circle>
                  <circle cx="306" cy="269" r="9.5"></circle>
                  <circle cx="343" cy="269" r="9.5"></circle>
                  <circle cx="380" cy="269" r="9.5"></circle>
                  <circle cx="306" cy="306" r="9.5"></circle>
                  <circle cx="343" cy="306" r="9.5"></circle>
                  <circle cx="380" cy="306" r="9.5"></circle>
                  <circle cx="343" cy="343" r="9.5"></circle>
                  <circle cx="380" cy="343" r="9.5"></circle>
                  <circle cx="380" cy="380" r="9.5"></circle>
                </g>
              </svg>
            </div>

            <h3 class="relative z-20 mb-4 -mt-1 text-4xl font-bold text-themevoilet-900">
              Getting Started is Easy...
            </h3>
            <p class="relative z-20 mb-6 text-lg text-themevoilet-100">
              Are you ready to start building the app of your dreams?
            </p>
            <div class="relative z-20 flex flex-col items-center w-full space-y-5 md:space-x-5 md:space-y-0 md:flex-row md:w-auto lg:flex-shrink-0 md:px-0">
              <a
                onClick={handleSubmit}
                href="#_"
                class="block w-full px-5 py-3 text-base font-medium leading-6 text-center text-themevoilet-100 transition duration-150 ease-in-out bg-blue-100 rounded-md md:inline-flex md:shadow-sm md:w-auto hover:bg-blue-50 focus:outline-none focus:shadow-outline"
              >
                Request a Demo
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partner;
