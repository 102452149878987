import React, { Component } from "react";
import classNames from "classnames";
import {
  AcademicCapIcon,
  LightBulbIcon,
  DesktopComputerIcon,
} from "@heroicons/react/outline";

const data = [
  {
    heading: "Modernize Education",
    text: "Hands-on labs that provide effective, reliable, and responsive learning through a stimulating and demonstrative approach to revolutionize education.",
    img: AcademicCapIcon,
    alt: "education",
  },
  {
    heading: "Integrating LMS",
    text: "Easily integrate with your preferred Learning Management System.",
    img: DesktopComputerIcon,
    alt: "lms",
  },
  {
    heading: "Content Development",
    text: "Making technology enabled content and courses as simple and powerful as possible.",
    img: LightBulbIcon,
    alt: "content development",
  },
];

export default class Timeline extends Component {
  render() {
    return (
      <div class="container  mx-auto w-full h-full">
        <div class="relative wrap overflow-hidden p-10 h-full">
          <div
            class="border-2-2 absolute border-opacity-20 border-gray-700 h-full border"
            style={{ left: "50%" }}
          ></div>

          {data.map((data, index) => {
            return (
              <div
                className={classNames(
                  "mb-8 flex justify-between items-center w-full ",
                  {
                    "right-timeline": index % 2 === 1,
                    "flex-row-reverse": index % 2 === 0,
                    "left-timeline": index % 2 === 0,
                  }
                )}
              >
                <div class="order-1 w-5/12"></div>
                <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                  <h1 class="mx-auto font-semibold text-lg text-white">
                    {index + 1}
                  </h1>
                </div>
                <div class="order-1  rounded-lg  text-gray-800 w-5/12 px-6 py-4">
                  {<data.img className="h-10 w-auto text-themevoilet-100" />}
                  <h3 class="text-2xl font-bold leading-10">{data.heading}</h3>
                  <p class="text-base font-normal leading-none text-gray-500 md:text-lg">
                    {data.text}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
