import React, { Component } from "react";
import { ReactComponent as MapImage } from "../../images/worldmap.svg";

export default class Network extends Component {
  render() {
    return (
      <section class="relative w-full h-auto py-8 overflow-hidden bg-white sm:py-12 md:py-20 lg:py-32  bg-gradient-to-t from-transparent via-slate-50 to-transparent">
        <div class="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent to-white"></div>
        <div class="relative flex flex-col items-center justify-start h-full mx-auto px-14 max-w-7xl lg:flex-row">
          <div class="relative z-10 w-full h-full lg:w-1/2 xl:pr-12 2xl:pr-24">
            <div class="flex flex-col items-start justify-center h-full pt-12 lg:pt-0">
              <h1 class="max-w-lg mx-auto text-4xl font-bold tracking-tight text-center text-gray-700 lg:mx-0 sm:text-5xl lg:text-6xl lg:text-left">
                <span class="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-themeblue-500">
                  World Wide
                </span>
                <p>accessiblilty...</p>
              </h1>
              <p class="max-w-md mx-auto mt-4 text-center text-gray-500 lg:mx-0 lg:text-left">
                Our data centers are located on every continent, making our
                services easily accessible from anywhere.
              </p>
              <div class="max-w-lg mx-auto lg:mx-0">
                <div class="flex items-center justify-center w-full my-6 space-x-2 lg:justify-start sm:space-x-6 lg:my-12">
                  <a
                    href="partner"
                    class="px-4 py-3 text-sm font-bold tracking-tight text-white transition duration-200 transform rounded-lg shadow-2xl sm:px-6 bg-gradient-to-tr from-themeblue-500 to-themevoilet-500 sm:text-lg hover:scale-105"
                  >
                    Book A Demo Today
                  </a>
                </div>
                <div class="hidden grid-cols-2 gap-8 sm:grid">
                  <div class="col-span-1 text-center lg:text-left">
                    <h4 class="text-3xl font-bold tracking-tight text-transparent lg:text-4xl bg-clip-text bg-gradient-to-r from-themeblue-500 to-themevoilet-500">
                      +10
                    </h4>

                    <p class="text-sm font-semibold text-gray-400">Data centers</p>
                  </div>
                  <div class="col-span-1 text-center lg:text-left">
                    <h4 class="text-3xl font-bold tracking-tight text-transparent lg:text-4xl bg-clip-text bg-gradient-to-r from-themeblue-500 to-themevoilet-500">
                      3
                    </h4>
                    <p class="text-sm font-semibold text-gray-400">Cloud Providers</p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>

          <div class="relative z-10 w-full h-full px-10 pb-32 mt-16 md:px-20 lg:px-5 xl:px-0 lg:pb-0 lg:mt-0 group">
            <div class="relative flex items-center justify-center w-full h-full">
              <div class="relative w-full h-auto md:h-full lg:h-auto">
                <div class="relative w-full h-auto overflow-hidden transition duration-300 transform rounded-xl group-hover:scale-95">
                  <MapImage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
