import React, { Component } from "react";
import AWSLogo from "../../images/logos/aws.png";
import AzureLogo from "../../images/logos/azure.png";
import GCPLogo from "../../images/logos/gcp.png";

// import BisleriLogo from "../../images/logos/ic_client (15).png";

export default class Logo extends Component {
  render() {
    return (
      <>
        <section class="box-border px-5 py-12 text-gray-800 bg-white xl:my-0">
          <span class="flex justify-center text-xs font-bold text-center text-gray-400 uppercase">
            Powered by
          </span>
          <div class="flex flex-wrap items-center justify-center px-5 mx-auto md:px-12 md:flex-wrap">
            {[
              AWSLogo, AzureLogo, GCPLogo
            ].map((logo) => (
              <div class="box-border inline-flex items-center px-5 py-0 my-2 text-center text-gray-800 grayscale opacity-50">
                <img
                  class="w-60 h-15 text-gray-500 fill-current"
                  src={logo}
                  alt="ß"
                />
              </div>
            ))}
          </div>
        </section>
      </>
    );
  }
}
