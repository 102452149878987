import { db } from "../firebase";

const addDataTotheDB = (data) => {
  try {
    return db.collection("contacts").add(data);
  } catch (err) {
    alert(err);
  }
};

export default addDataTotheDB;
