import React, { Component } from "react";
import { ReactComponent as HeroIllustation } from "../../images/heroillustration.svg";

export default class Hero extends Component {
  render() {
    return (
      <>
        <section class="pt-24 pb-32 bg-white">
          <div class="px-8 mx-auto max-w-7xl lg:px-12 xl:px-16">
            <div class="flex flex-col lg:flex-row">
              <div class="flex items-center w-full lg:w-1/2 lg:pr-10">
                <div class="relative max-w-lg mx-auto font-sans text-center text-gray-700 border-gray-200 lg:text-left">
                  <h1 style={{lineHeight: "1.25"}} class="m-0 text-3xl font-extrabold text-left text-transparent bg-clip-text bg-gradient-to-l from-themeblue-900 to-themevoilet-500 border-solid sm:text-4xl md:text-6xl leading-tight">
                    Power of Cloud and Blockchain, Reimagined!
                  </h1>
                  <p class="pr-5 mx-0 my-4 text-base text-left text-gray-500 xl:leading-9 lg:my-8 xl:text-xl">
                    Choose from a variety of virtual machines and blockchain solutions on-demand in the
                    browser
                  </p>

                  <div class="relative flex flex-col sm:flex-row sm:space-x-4 ">
                    <a
                      href="#_"
                      class="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-gradient-to-tr from-themeblue-500 to-themevoilet-500 rounded-md sm:mb-0 hover:bg-themeblack-100 sm:w-auto"
                    >
                      Try It Free
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5 ml-1"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>

              <div class="relative w-full mt-20 lg:w-1/2 lg:mt-0">
                <div class="relative z-20 w-full mt-4 ">
                  <div class="flex items-center justify-center m-auto  w-5/6 h-auto overflow-hidden transform rounded-lg">
                    <HeroIllustation />
                  </div>
                </div>

                <div class="absolute top-0 right-0 z-10 w-full h-full mt-4 mr-5 transform scale-125 rotate-45">
                  <svg
                    class="w-full h-full transform opacity-50 fill-current lg:scale-x-100 text-blue-50"
                    viewBox="0 0 200 200"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M37.3,-64.3C51.3,-56.4,67.9,-52.6,76.3,-42.5C84.7,-32.5,84.9,-16.3,80.3,-2.7C75.6,10.9,66.2,21.8,60.2,36.1C54.2,50.3,51.8,67.9,42.3,76.4C32.9,84.9,16.4,84.3,2.4,80.2C-11.7,76.2,-23.4,68.5,-36.8,62.3C-50.1,56,-65,51.1,-73.2,40.9C-81.4,30.7,-82.9,15.4,-78.6,2.5C-74.4,-10.4,-64.3,-20.9,-58.2,-34.6C-52,-48.3,-49.7,-65.3,-40.7,-76C-31.6,-86.8,-15.8,-91.4,-2.1,-87.8C11.6,-84.1,23.2,-72.2,37.3,-64.3Z"
                      transform="translate(100 100)"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
