import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class MobileApp extends Component {
  render() {
    return (
      <section class="bg-white">
        <div class="flex flex-col-reverse items-center justify-center max-w-5xl px-10 pt-16 mx-auto md:flex-row md:space-x-20 lg:space-x-32 md:pt-24">
          <div class="box-content relative flex items-start justify-center flex-shrink-0 pb-16 border-2 border-b-0 border-gray-900 w-9/12 rounded-t-3xl h-96">
            <div class="absolute z-20 h-8 mx-auto bg-white w-44 rounded-b-xl"></div>
            <div class="absolute w-full h-full p-3 pb-0">
              {/* className="background-inner" */}

              <div
                class="box-content relative z-10 w-full h-full bg-gray-100 rounded-t-xl adventure-animation"
                style={{
                  fontSize: "100px",
                  color: `linear-gradient(326deg, #a4508b 0%, #5f0a87 74%)`,
                }}
              ></div>
            </div>
          </div>
          <div class="flex flex-col items-start w-full h-full max-w-xs pb-10 space-y-5 md:pb-0 md:max-w-none">
            <h2 class="text-5xl font-black text-gray-900">
              Witness {" "}
              <span className="text-transparent bg-clip-text  bg-gradient-to-r from-themeblue-500 to-themevoilet-500">
                metawurks
              </span>{" "}
              magic.
            </h2>

            <p class="text-lg text-gray-500">
            Get onboard with our services to deliver highly reliable virtual machines 
            and we are one of the fastest growing networks in the world.
            </p>
            <Link
                to="/partner"
                class="inline-flex tracking-wide uppercase text-xs items-center sm:mt-0 mt-5 justify-center px-8 py-3.5 font-semibold text-white bg-gray-800 border border-transparent rounded-md shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-offset-2 focus:ring-gray-900"
              >
                Book A Demo
              </Link>
          </div>
        </div>
      </section>
    );
  }
}
