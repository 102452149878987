import React, { Component } from "react";
import Timeline from "./Timeline";
import ILinkLogo from "../../images/logos/ilink.png";
import AarnaLogo from "../../images/logos/aarna.png";
import Z3Logo from "../../images/logos/Z3.png";


const data = [
  { src: ILinkLogo, alt: "iLink Digital" },
  { src: AarnaLogo, alt: "Aarna Networks" },
  { src: Z3Logo, alt: "Z3" },
];
export default class About extends Component {
  render() {
    return (
      <>
        <section class="relative block py-20 overflow-hidden leading-6 text-left text-indigo-900 bg-white">
          <div class="w-full max-w-2xl px-16 mx-auto leading-6 text-left sm:px-12 md:px-8 xl:px-12">
            <div class="relative w-full px-4 leading-6 text-center xl:flex-grow-0 xl:flex-shrink-0 lg:flex-grow-0 lg:flex-shrink-0">
              <div class="box-border text-sm font-semibold text-gray-700 uppercase">
                Our journey
              </div>
              <h2 class="box-border mx-0 mt-6 mb-0 font-sans text-4xl font-bold leading-tight sm:text-5xl md:text-6xl text-transparent  bg-clip-text bg-gradient-to-l from-themeblue-900 to-themevoilet-500">
                How it all started!
              </h2>
            </div>
          </div>
        </section>

        <section class="w-full px-8 pt-20 pb-16 bg-white xl:px-0">
          <div class="flex flex-col items-start max-w-6xl mx-auto md:flex-row">
            <h3 class="w-full text-4xl font-extrabold tracking-normal text-gray-900 sm:text-5xl md:text-5xl md:pr-10 lg:pr-16 xl:pr-20 md:leading-none md:-mt-2 md:w-1/2">
              Virtual machines that help you tell your story
            </h3>
            <div class="flex flex-col w-full mt-8 space-y-5 md:w-1/2 md:space-y-10 md:mt-0">
              <p class="col-span-6 text-base font-normal text-gray-700 lg:leading-8 xl:leading-8 md:text-xl">
                Our goal is to provide immersive content experiences for
                students, enabling consistent and seamless engagement between
                tutors and students.
              </p>

              <p class="col-span-6 text-base font-normal text-gray-700 lg:leading-8 xl:leading-9 md:text-xl">
                With Metawurks, organizations and educational institutions of
                all sizes can offer impactful learning experiences with minimal
                management costs.
              </p>
            </div>
          </div>
        </section>
        <Timeline />

        <section class="w-full py-12 bg-white lg:py-24">
          <div class="max-w-6xl px-12 mx-auto text-center">
          <div class="space-y-12 md:text-center">
              <div class="max-w-3xl mb-20 space-y-5 sm:mx-auto sm:space-y-4">
                <h2 class="relative text-4xl font-extrabold tracking-tight sm:text-5xl">
                  Our Awesome Partners
                </h2>
                <p class="text-xl text-gray-500">
                  We take pride in the people we work with. This is because we
                  all collectively help each other become more awesome every
                  day.
                </p>
              </div>
            </div>

            {/* grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3 */}
            <div class="flex flex-row text-gray-800 grayscale opacity-50">
              {data.map((d, index) => (
                <img className="basis-1/3 mx-20" src={d.src} alt={d.alt} width={220} />
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}
