import React, { useState } from "react";
import addDataTotheDB from "../../api/api";

const Contact = () => {
  const [fname, setfName] = useState("");
  const [lname, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    addDataTotheDB({
      first_name: fname,
      last_name: lname,
      email: email,
      subject: subject,
      message: message,
      page: "bookademo",
    })
      .then(() => {
        setLoader(false);
        alert(
          "We have received your message. Please give us 2-3 business days to respond."
        );
        setfName("");
        setlName("");
        setEmail("");
        setSubject("");
        setMessage("");
      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });
  };

  return (
    <div>
      <section class="py-20 bg-gray-50">
        <div class="container items-center max-w-6xl px-4 px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
          <div class="flex flex-wrap items-center -mx-3">
            <div class="order-1 w-full px-3 lg:w-1/2 lg:order-0">
              <div class="w-full lg:max-w-md">
                <h2 class="mb-4 text-3xl font-bold leading-tight tracking-tight sm:text-4xl font-heading">
                  Are you ready to learn our Ecosytem?
                </h2>
                <p class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6">
                  You're at the right place!
                </p>
              </div>
            </div>
            <div class="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
              <img
                class="mx-auto sm:max-w-sm lg:max-w-full"
                src="https://cdn.devdojo.com/images/november2020/feature-graphic.png"
                alt="feature graphic"
              />
            </div>
          </div>
        </div>
      </section>

      <div class="py-10 bg-white md:py-16">
        <div class="px-10 mx-auto max-w-7xl md:px-16">
          <div class="max-w-3xl mx-auto mb-10 md:mb-16">
            <p class="text-xs font-bold text-themevoilet-100 uppercase">
              Contact Us
            </p>
            <h2 class="mt-1 text-2xl font-bold text-left text-gray-800 lg:text-3xl md:mt-2">
              Need to ask us a question?
            </h2>
          </div>
          <form class="grid max-w-3xl gap-4 mx-auto sm:grid-cols-2">
            <div>
              <label
                for="first-name"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                First name
              </label>
              <input
                onChange={(e) => setfName(e.target.value)}
                value={fname}
                name="first-name"
                class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              />
            </div>

            <div>
              <label
                for="last-name"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                Last name
              </label>
              <input
                value={lname}
                onChange={(e) => setlName(e.target.value)}
                name="last-name"
                class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              />
            </div>

            <div class="sm:col-span-2">
              <label
                for="email"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                Email
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              />
            </div>

            <div class="sm:col-span-2">
              <label
                for="subject"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                Subject
              </label>
              <input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                name="subject"
                class="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              />
            </div>

            <div class="sm:col-span-2">
              <label
                for="message"
                class="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                Message
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                class="w-full h-64 px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-blue-300"
              ></textarea>
            </div>

            <div class="flex items-center justify-between sm:col-span-2">
              <button
                onClick={handleSubmit}
                class="inline-block px-8 py-3 text-sm font-semibold text-center text-white transition duration-100 bg-themevoilet-100 rounded-md outline-none hover:bg-blue-500 active:bg-blue-700 ring-blue-300 md:text-base"
              >
                Submit
              </button>
            </div>
          </form>

          <p class="max-w-3xl mx-auto mt-5 text-xs text-gray-400">
            Please allow up to 24-48 hour response during the weekdays.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
