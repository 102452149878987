import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../images/Meta.svg";

export default class Header extends Component {
  render() {
    return (
      <>
        <section class="w-full px-8 text-gray-700 bg-white ">
          <div class="flex flex-col flex-wrap items-center justify-between py-6 mx-auto md:flex-row max-w-7xl">
            <Link to="/">
              <div class="relative items-left flex flex-col sm:flex-row">
                <Logo className="h-12 w-auto" />
              </div>
            </Link>

            <div class="inline-flex flex-col items-center sm:flex-row sm:ml-5 lg:justify-end">
              <nav class="flex flex-wrap items-center space-x-4 text-xs font-semibold tracking-wide uppercase sm:space-x-6 z-[1000]">
                <Link to="/" class="text-gray-400 hover:text-gray-500">
                  Home
                </Link>
                <Link to="/about" class="text-gray-400 hover:text-gray-500">
                  About
                </Link>
                
                <Link to="/bookdemo" class="text-gray-400 hover:text-gray-500">
                  Book A Demo
                </Link>
                <Link to="/contact" class="text-gray-400 hover:text-gray-500">
                  Contact
                </Link>
              </nav>

              <button
                type="Login"
                class="inline-flex tracking-wide uppercase text-xs items-center sm:mt-0 mt-5 sm:ml-8 justify-center px-5 py-2.5 font-semibold text-white bg-gradient-to-tr from-themeblue-500 to-themevoilet-500 border border-transparent rounded-md shadow-sm hover:bg-themeblack-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "http://lms-dev.metawurks.com";
                }}
              >
                Login
              </button>
            </div>
          </div>
        </section>
      </>
    );
  }
}
