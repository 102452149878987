import React, { Component } from "react";
import Tilt from "react-parallax-tilt";
import { ReactComponent as AnalyticsIllustration } from "../../images/services/analytics.svg";
import { ReactComponent as SecurityIllustration } from "../../images/services/security.svg";
import { ReactComponent as UserIllustration } from "../../images/services/userexperience.svg";

export default class Content extends Component {
  render() {
    return (
      <div>
        <section className="w-full bg-white pt-7 pb-7 md:pt-20 md:pb-24">
          <div className="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-7xl lg:px-16">
            <div className="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
              <div className="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20 ">
                <Tilt>
                  <UserIllustration />
                </Tilt>
              </div>
            </div>

            <div className="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
              <h2 className="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                User Experience
              </h2>
              <p className="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
                With a modern browser and internet connection, our labs can be
                accessed from anywhere in the world.
              </p>
              <ul className="p-0 m-0 leading-6 border-0 border-gray-300">
                <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                  <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-themevoilet-100 rounded-full">
                    <span className="text-sm font-bold">✓</span>
                  </span>{" "}
                  Collaborative working among participants.
                </li>
                <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                  <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-themevoilet-100 rounded-full">
                    <span className="text-sm font-bold">✓</span>
                  </span>{" "}
                  As in physical classrooms, instructors can manage the show
                  effectively.
                </li>
                <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                  <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-themevoilet-100 rounded-full">
                    <span className="text-sm font-bold">✓</span>
                  </span>{" "}
                  We provide 24/7 support for all technical and non-technical
                  issues.
                </li>
              </ul>
            </div>
          </div>
          <div className="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl lg:px-16">
            <div className="box-border w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-32">
              <h2 className="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                Security
              </h2>
              <p className="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-10 lg:text-lg">
                It is secure and protected against attacks on the application
                and network layers.
              </p>
              <ul className="p-0 m-0 leading-6 border-0 border-gray-300">
                <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                  <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-themevoilet-100 rounded-full">
                    <span className="text-sm font-bold">✓</span>
                  </span>{" "}
                  All users are session authenticated.
                </li>
                <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                  <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-themevoilet-100 rounded-full">
                    <span className="text-sm font-bold">✓</span>
                  </span>{" "}
                  Access labs from one browser per user at a time.
                </li>
                <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                  <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-themevoilet-100 rounded-full">
                    <span className="text-sm font-bold">✓</span>
                  </span>{" "}
                  Data is protected and treated in accordance with GDPR.
                </li>
              </ul>
            </div>
            <div className="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
              <div className="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20 ">
                <Tilt>
                  <SecurityIllustration />
                </Tilt>
              </div>
            </div>
          </div>
          <div className="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-7xl lg:px-16">
            <div className="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
              <div className="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20 ">
                {" "}
                <Tilt>
                  <AnalyticsIllustration />
                </Tilt>
              </div>
            </div>

            <div className="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
              <h2 className="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                Reporting and Analytics
              </h2>
              <p className="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
                Track the number of active users and sessions with our admin
                portal
              </p>
              <ul className="p-0 m-0 leading-6 border-0 border-gray-300">
                <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                  <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-themevoilet-100 rounded-full">
                    <span className="text-sm font-bold">✓</span>
                  </span>{" "}
                  Add and remove users seemlessly
                </li>
                <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                  <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-themevoilet-100 rounded-full">
                    <span className="text-sm font-bold">✓</span>
                  </span>{" "}
                  Session management of VMs
                </li>
                <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                  <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-themevoilet-100 rounded-full">
                    <span className="text-sm font-bold">✓</span>
                  </span>{" "}
                  Switch between cloud providers
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
