import React, { Component } from "react";
// import { Container } from "./styles/Layouts";
import Hero from "../landing-page/Hero";
import ContentPage from "../landing-page/Content";
import Services from "../landing-page/Services";
import Logo from "../landing-page/Logo";
import MobileApp from "../landing-page/MobileApp";
import Network from "../landing-page/Network";
import Quote from "../landing-page/Quote";
import Pricing from "../landing-page/Pricing";

export default class Main extends Component {
  render() {
    return (
      <>
        {/*Landing */}
        <Hero />

        {/* Logos */}
        <Logo />

        {/* Network */}
        <Network />

        {/**Features */}
        <Services />

        {/**Content */}
        <ContentPage />

        {/* Quote */}
        <Quote />

        {/* Pricing */}
        <Pricing />

        {/* MobileApp */}
        <MobileApp />
      </>
    );
  }
}
